'use strict'
import React, { Component } from 'react'
import { render } from 'react-dom'

import Carousel from './carousel'
var container = document.getElementById('react-carousel');

render(
    <Carousel {...(container.dataset)} />,
    container
  )