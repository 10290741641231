import React, { Component } from 'react'


const factorSelectedImage = 1.5;
const ImageWithRatio = ({index, indexSelected, imageUrl, setIndexSelected, carousselRef, isMobileViewPort }) => {
    const [isSelected, setIsSelected] = React.useState(index + 1 === indexSelected)
    const [imageWidth, setImageWidth] = React.useState()
    const [imageHeight, setImageHeight] = React.useState()
    const [style, setStyle] = React.useState({
        '--offset': index + 1,
        'height': '0px',
        'width': '0px'
    })
    let src = `media/${imageUrl}`;
    const imageSize = React.useMemo(() => isMobileViewPort ? 150 : 300, [isMobileViewPort]);
    React.useEffect(() => {
        setIsSelected(index + 1 === indexSelected);
    }, [indexSelected]);

    React.useEffect(() => {
        if (!imageWidth || !imageHeight) return;

        let newImageHeight, newImageWidth;
        let aspectRatio = imageWidth / imageHeight;
        if (aspectRatio > 1) {
            newImageHeight = imageSize / aspectRatio;
            newImageWidth = imageSize;
        } else {
            newImageHeight = imageSize;
            newImageWidth = imageSize * aspectRatio;
        }
        setStyle({
            '--offset': index + 1,
            'maxHeight': isSelected ? `${newImageHeight * factorSelectedImage}px` : `${newImageHeight}px`,
            'maxWidth': isSelected ? `${newImageWidth * factorSelectedImage}px` : `${newImageWidth}px`
        });
    }, [isMobileViewPort, isSelected, imageWidth, imageHeight, indexSelected]);

    const onLoadImage = (e) => {
        setImageWidth(e.target.naturalWidth);
        setImageHeight(e.target.naturalHeight);
    }

    const onClickImage = (e, index) => {
        if(!(index + 1 === indexSelected)){
            e.preventDefault();
        }
        if (carousselRef.current) {
            carousselRef.current.style.setProperty('--position', index + 1);
            setIndexSelected(index + 1);
        }
    }

    return (
        <a 
            key={index}
            className="item"
            style={style}
            onClick={(e) => onClickImage(e,index)}
            data-fancybox={isSelected ? "galery" : ""}
            href={isSelected ? src : ""}
        >
            <img
                src={src}
                onLoad={onLoadImage}
            />
        </a>
    )
}

export default Carousel = ({images}) => {
    const image_urls = JSON.parse(images);
    const caroussel = React.useRef(null)

    const [isMobileViewPort, setIsMobileViewPort] = React.useState(window.innerWidth < 600)
    const [imageUrls, setImageUrls] = React.useState(image_urls)
    const [indexSelected, setIndexSelected] = React.useState(2)
    const [lastScrollTime, setLastScrollTime] = React.useState(0)

    const handleScroll = React.useCallback((deltaY) => {
        const nextRadioIndex = deltaY > 0 ? indexSelected + 1 : indexSelected - 1;
        if (nextRadioIndex < 1 || nextRadioIndex > imageUrls.length) return;
        setIndexSelected(nextRadioIndex);
        caroussel.current.style.setProperty('--position', nextRadioIndex);
    }, [indexSelected, setIndexSelected, imageUrls.length]);

    React.useEffect(() => {
        const updateHeight = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
    
        // Initial call to set the height
        updateHeight();
    
        // Add event listener to update height on resize
        window.addEventListener('resize', updateHeight);
    
        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, []);

    React.useEffect(() => {
        caroussel.current.style.setProperty('--position', indexSelected);
        caroussel.current.style.setProperty('--deltaImage', isMobileViewPort ? '200px':'400px');
    }, [caroussel.current]);

    React.useEffect(() => {
        const handleResize = () => {
            const newIsMobileViewPort = window.innerWidth < 600;
            if(newIsMobileViewPort !== isMobileViewPort){
                let deltaImage = newIsMobileViewPort ? 200 : 400;
                caroussel.current.style.setProperty('--deltaImage', `${deltaImage}px`);
                setIsMobileViewPort(newIsMobileViewPort);
            }
        };

        window.addEventListener('resize', handleResize);

        // Nettoyage de l'écouteur d'événements lors du démontage du composant
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMobileViewPort]);

    React.useEffect(() => {
        const scrollThreshold = 300;
        let lastTouchY = 0;
        let lastTouchX = 0;

        const handleWheel = (event) => {
            const currentTime = new Date().getTime();
            if (currentTime - lastScrollTime > scrollThreshold) {
                setLastScrollTime(currentTime);
                handleScroll(event.deltaY);
            }
        };

        const handleTouchStart = (event) => {
            lastTouchY = event.touches[0].clientY;
            lastTouchX = event.touches[0].clientX;
        };
    
        const handleTouchMove = (event) => {
            const currentTime = new Date().getTime();
            if (currentTime - lastScrollTime > scrollThreshold) {
                setLastScrollTime(currentTime);
                const deltaY = lastTouchY - event.touches[0].clientY;
                const deltaX = lastTouchX - event.touches[0].clientX;
                handleScroll(deltaX); // Vous pouvez aussi utiliser deltaX si nécessaire
                lastTouchY = event.touches[0].clientY;
                lastTouchX = event.touches[0].clientX;
            }
        };

        caroussel.current.addEventListener('wheel', handleWheel);
        caroussel.current.addEventListener('touchstart', handleTouchStart);
        caroussel.current.addEventListener('touchmove', handleTouchMove);
        return () => {
            caroussel.current.removeEventListener('wheel', handleWheel);
            caroussel.current.removeEventListener('touchstart', handleTouchStart);
            caroussel.current.removeEventListener('touchmove', handleTouchMove);
        };
    }, [handleScroll, lastScrollTime]);

    return (
        <>
            <div className="h-full" ref={caroussel} id="carousel" data-numimages={imageUrls.length}>
                {imageUrls.map((image, index) => {
                    if (Math.abs(index - indexSelected) > 10){
                        return
                    }
                    return (
                        <ImageWithRatio
                            key={index}
                            index={index}
                            carousselRef={caroussel}
                            indexSelected={indexSelected}
                            setIndexSelected={setIndexSelected}
                            imageUrl={image} 
                            isMobileViewPort={isMobileViewPort}
                            />
                    )
                })}
            </div>
        </>
    )
}
